import MultiSelectComponent from '../CommonComponents/SelectComponent/MultiSelectComponent';
import React, {useEffect, useState} from 'react';
import {imagePath} from '../GlobalStateComponent/GlobalState';
import {
    AddSegmentBox,
    AddSegmentContainer,
    RemoveSegmentButton
} from '../SendCampaignComponent/SendCampaignComponent.styled';
import SingleSelectComponent from '../CommonComponents/SelectComponent/SingleSelectComponent';
import {BodySemiBold} from '../CommonComponents/Typography/Typography.styled';
import {GeoAreaSelect} from './GeoAreaSelect';
import {toTitleCase} from '../../utils/utilities';
import apiServices from '../../services/api-services';

export const segmentOptions = [
    {value: 'lga', label: 'Local Government Area (LGA)'},
    {value: 'postcode', label: 'Postcode'},
    {value: 'suburb', label: 'Suburb'}
];

export const containOptions = [
    {value: 'contain', label: 'Contains'},
    {value: 'notContain', label: 'Does not contain'}
];

const AddSegmentComponent = ({
    segmentKey,
    setSegments,
    refContainerWidth,
    isFirstCondition,
    setShowAddSegmentButton
}) => {
    const [inputErrors, setInputErrors] = useState({
        selectCondition: {hasError: false, errorMessage: 'Select LGAs, suburbs or postcodes'},
        selectContain: {hasError: false, errorMessage: 'Select one condition: contains or does not contain'},
        selectLGAs: {hasError: false, errorMessage: 'Select at least one LGA'},
        selectSuburbs: {hasError: false, errorMessage: 'Select at least one suburb'},
        selectPostcodes: {hasError: false, errorMessage: 'Select at least one postcode'}
    });
    const [selectedSegment, setSelectedSegment] = useState(null);
    const [selectedContain, setSelectedContain] = useState(containOptions[0].value);
    const [selectedAttrs, setSelectedAttrs] = useState({
        lga: [],
        suburb: [],
        postcode: []
    });

    const [showDisabledAttr, setShowDisabledAttr] = useState(true);
    const formattedLgaData = (data) => {
        return data
            .sort((a, b) => a.localeCompare(b))
            .map(lga => ({value: lga, label: toTitleCase(lga.toLocaleLowerCase())}));
    };
    const formattedSuburbData = (data) => {
        return data
            .map(suburb => ({
                value: `${suburb.suburbName.replace(/\s+/g, '-')}-${suburb.postCode}`,
                label: `${toTitleCase(suburb.suburbName.toLowerCase())}, ${suburb.postCode}`
            }));
    };
    const formattedPostCodeData = (data) => {
        return data
            .map(pc => ({
                value: pc.postCode,
                label: pc.postCode
            }));
    };
    useEffect(() => {
        const selectedValues = selectedAttrs[selectedSegment];
        if (selectedSegment) {
            setSegments(prevState => {
                return {
                    ...prevState,
                    [segmentKey]: {[selectedSegment]: {[selectedContain]: selectedValues}}
                };
            });
        }
    }, [selectedSegment, selectedContain, selectedAttrs, segmentKey, setSegments]);

    const handleSegmentChange = (selectedOption) => {
        setShowDisabledAttr(false);
        setInputErrors(prevState => ({
            ...prevState,
            selectCondition: {hasError: false, errorMessage: ''}
        }));
        setSelectedSegment(selectedOption.value);
    };

    const handleContainChange = (selectedOption) => {
        setSelectedContain(selectedOption.value);
    };

    const handleMultiSelectOptionChange = (selectedOptions) => {
        setInputErrors(prevState => ({
            ...prevState,
            selectLGAs: {hasError: false, errorMessage: ''},
            selectPostcodes: {hasError: false, errorMessage: ''},
            selectSuburbs: {hasError: false, errorMessage: ''}
        }));

        setSelectedAttrs(prevState => ({
            ...prevState,
            [selectedSegment]: selectedOptions.map(option => option.value)
        }));
    };

    const handleRemoveSegment = () => {
        setSegments(prevState => {
            const {[segmentKey]: removedSegment, ...restOfSegments} = prevState;
            return restOfSegments;
        });
        setShowAddSegmentButton(true);
    };
    const deleteIcon = <
        img src={ `${imagePath}/bin.svg` }
        alt={ `bin-img-${segmentKey}` }
        style={ {marginRight: '6px'} }
    />;

    const removeSegmentButton = <RemoveSegmentButton
        variant='tertiary'
        data-testid={ `remove-segment-${segmentKey}` }
        onClick={ handleRemoveSegment }
        iconStart={ deleteIcon }>
        Remove condition
    </RemoveSegmentButton>;

    return <>
        <AddSegmentBox data-testid={ `add-segment-condition-${segmentKey}` }
            style={ {width: `${refContainerWidth - 40}px`} }>
            <AddSegmentContainer>
                <BodySemiBold>
                    {isFirstCondition ? 'Customers who meet ALL the following conditions:' : 'AND'}
                </BodySemiBold>
                <div style={ {display: 'flex', justifyContent: 'space-between'} }>
                    <div style={ {marginRight: '0.5rem', marginTop: '0.5rem', width: '50%'} }>
                        <SingleSelectComponent
                            label={ `Select segment ${segmentKey}` }
                            name={ `condition-${segmentKey}` }
                            options={ segmentOptions }
                            onChange={ handleSegmentChange }
                            placeholder='Select condition'
                            hideLabel
                            errorMessage={ inputErrors.selectCondition.errorMessage }
                            hasError={ inputErrors.selectCondition.hasError }
                        />
                    </div>
                    <div style={ {marginRight: 0, marginTop: '0.5rem', width: '50%'} }>
                        <SingleSelectComponent
                            label={ `Select contain condition-${segmentKey}` }
                            name={ `contains-${segmentKey}` }
                            options={ containOptions }
                            onChange={ handleContainChange }
                            hideLabel
                            errorMessage={ inputErrors.selectContain.errorMessage }
                            hasError={ inputErrors.selectContain.hasError }
                            defaultValue={ containOptions[0] }
                            isDisabled // TODO: disabled until the backend is ready for processing not-contain conditions
                        />
                    </div>
                </div>
                {showDisabledAttr &&
                    <div data-testid={ `disabled-attribute-multi-select-${segmentKey}` } style={ {marginTop: '-0.5rem'} }>
                        <MultiSelectComponent
                            name='placeholder-disabled-attribute'
                            placeholder='Select attribute'
                            hideLabel
                            width={ `${refContainerWidth - 72}px` }
                            isDisabled
                        />
                    </div>}
                <GeoAreaSelect
                    name={ 'lga' }
                    label={ `Select LGAs-${segmentKey}` }
                    showDropdown={ selectedSegment === segmentOptions[0].value }
                    errorMessage={ inputErrors.selectLGAs.errorMessage }
                    hasError={ inputErrors.selectLGAs.hasError }
                    segmentKey={ segmentKey }
                    handleMultiSelectOptionChange={ handleMultiSelectOptionChange }
                    refContainerWidth={ refContainerWidth }
                    fetchApi={ apiServices.fetchLgaList }
                    cacheKey={ 'LGA_LIST' }
                    formatData={ formattedLgaData }
                />
                <GeoAreaSelect
                    name={ 'postcode' }
                    label={ `Select postcodes-${segmentKey}` }
                    showDropdown={ selectedSegment === segmentOptions[1].value }
                    errorMessage={ inputErrors.selectPostcodes.errorMessage }
                    hasError={ inputErrors.selectPostcodes.hasError }
                    segmentKey={ segmentKey }
                    handleMultiSelectOptionChange={ handleMultiSelectOptionChange }
                    refContainerWidth={ refContainerWidth }
                    fetchApi={ apiServices.fetchPostCodeList }
                    cacheKey={ 'POSTCODE_LIST' }
                    formatData={ formattedPostCodeData }/>
                <GeoAreaSelect
                    name={ 'suburb' }
                    label={ `Select suburbs-${segmentKey}` }
                    showDropdown={ selectedSegment === segmentOptions[2].value }
                    errorMessage={ inputErrors.selectSuburbs.errorMessage }
                    hasError={ inputErrors.selectSuburbs.hasError }
                    segmentKey={ segmentKey }
                    handleMultiSelectOptionChange={ handleMultiSelectOptionChange }
                    refContainerWidth={ refContainerWidth }
                    fetchApi={ apiServices.fetchSuburbList }
                    cacheKey={ 'SUBURB_LIST' }
                    formatData={ formattedSuburbData }
                />
            </AddSegmentContainer>
            {removeSegmentButton}
        </AddSegmentBox>
    </>;
};

export default AddSegmentComponent;