import React, {useContext, useEffect, useState} from 'react';
import {Divider} from '@mui/material';
import {colors} from '../CommonComponents/Typography/Typography.styled';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalculator} from '@fortawesome/free-solid-svg-icons';
import CustomerCountComponent from '../CommonComponents/CustomerCountComponent/CustomerCountComponent';
import apiServices from '../../services/api-services';
import {SecondaryButton} from '../CommonComponents/CustomComponents/CustomComponents.styled';
import {CampaignContext} from '../SendCampaignComponent/SendCampaignComponent';

const EstimateCustomersComponent = ({selectedConditions}) => {
    const [showEstimateButton, setShowEstimateButton] = useState(false);
    const [estimatedConditions, setEstimatedConditions] = useState({
        lga: {
            contain: [],
            notContain: []
        },
        suburb: {
            contain: [],
            notContain: []
        },
        postcode: {
            contain: [],
            notContain: []
        }
    });
    const {segmentCustomerCount, setSegmentCustomerCount} = useContext(CampaignContext);
    const [showCustomerCount, setShowCustomerCount] = useState(false);
    const [customerCountErrorMsg, setCustomerCountErrorMsg] = useState('');

    const _ = require('lodash');
    useEffect(() => {
        if (!_.isEqual(estimatedConditions, selectedConditions)) {
            setShowEstimateButton(true);
            setShowCustomerCount(false);
            setSegmentCustomerCount(undefined);
        } else {
            setShowEstimateButton(true);
        }
        if (selectedConditions.lga?.contain.length === 0) {
            setShowEstimateButton(false);
            setShowCustomerCount(false);
            setSegmentCustomerCount(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedConditions, estimatedConditions, setShowEstimateButton]);

    const handleEstimateButtonClick = () => {
        setEstimatedConditions(prevState => {
            return {
                ...prevState,
                lga: selectedConditions.lga,
                suburb: selectedConditions.suburb,
                postcode: selectedConditions.postcode
            };
        });
        setShowEstimateButton(false);

        const lgaToInclude = selectedConditions.lga.contain;
        const lgaToExclude = selectedConditions.lga.notContain;
        const lgaListToEstimate = lgaToInclude?.filter((item) => {
            return !lgaToExclude?.includes(item);
        });

        if (lgaListToEstimate.length === 0) {
            setShowCustomerCount(true);
            setSegmentCustomerCount(undefined);
            setCustomerCountErrorMsg('Please check your LGA selection and try again');
        } else {
            apiServices.createLgaCustomerSummary(lgaListToEstimate)
                .then(response => {
                    const totalResidentsInLgas = response.data.totalResidentsInLgas;
                    setShowCustomerCount(true);
                    const totalResidentsString = Number(totalResidentsInLgas);
                    if (!isNaN(totalResidentsString)) {
                        setSegmentCustomerCount(totalResidentsString);
                    }
                })
                .catch(error => {
                    console.error('Error fetching total residents data:', error);
                    setShowCustomerCount(true);
                    setSegmentCustomerCount(undefined);
                    setCustomerCountErrorMsg('Please try again later');
                });
        }
    };

    return <>
        {showEstimateButton && <>
            <Divider color={ colors.grey.geyser } width={ '100%' } style={ {marginTop: '20px', marginBottom: '20px'} }/>
            <SecondaryButton variant='secondary' data-testid='estimate-customer-btn'
                onClick={ handleEstimateButtonClick }>
                <FontAwesomeIcon icon={ faCalculator } style={ {marginRight: '8px'} }/>
                Estimate number of customers
            </SecondaryButton>
        </>}

        {showCustomerCount &&
            <CustomerCountComponent noOfCustomers={ segmentCustomerCount } errorMessage={ customerCountErrorMsg }
                setShowCustomerCount={ setShowCustomerCount }
            />}
    </>;
};

export default EstimateCustomersComponent;