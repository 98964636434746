import styled from 'styled-components';
import {FormRadioGroup} from '@snsw/react-component-library';

export const ServiceRadioGroup = styled(FormRadioGroup)`
    :disabled {
        input {
            opacity: 0.75;
        }
    }
    .radio-item:nth-child(1) {
        width: 40%;
    }
    .radio-item:nth-child(2) {
        width: 60%;
    }
`;
export const CharactersCounter = styled.span`
    float: right;
    font-size: 12px;
    color: #646974;
    margin-top: 5px
`;