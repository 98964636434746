import React, {useEffect, useRef, useState} from 'react';
import MultiSelectComponent from '../CommonComponents/SelectComponent/MultiSelectComponent';

const CACHE_EXPIRY = 1000 * 60 * 60 * 24; // 1 day

export const GeoAreaSelect = ({
    name, label, showDropdown, errorMessage, hasError,
    segmentKey, handleMultiSelectOptionChange, refContainerWidth,
    fetchApi, cacheKey, formatData,
}) => {

    const hasFetched = useRef(false);

    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (hasFetched.current) {return;} // Prevent double execution
        hasFetched.current = true;

        (async () => {
            const cachedData = localStorage.getItem(cacheKey);
            if (cachedData) {
                const { data, timestamp } = JSON.parse(cachedData);
                if (Date.now() - timestamp < CACHE_EXPIRY) {
                    setOptions(data);
                    return;
                }
            }

            try {
                const fetchedData = await fetchApi();
                if (Array.isArray(fetchedData)) {
                    const formattedData = formatData(fetchedData);
                    localStorage.setItem(cacheKey, JSON.stringify({
                        data: formattedData,
                        timestamp: Date.now()
                    }));
                    setOptions(formattedData);
                } else {
                    throw new Error(`Invalid data format for ${cacheKey}`);
                }
            } catch (error) {
                console.error(`Error fetching data for ${cacheKey}:`, error);
            }
        })();
    }, [fetchApi, cacheKey, formatData]);

    return (
        <div style={ {display: showDropdown ? 'block' : 'none', marginTop: '-0.5rem'} }>
            <MultiSelectComponent
                name={ name }
                placeholder='Select attribute'
                errorMessage={ errorMessage }
                hasError={ hasError }
                label={ label }
                options={ options }
                onOptionChange={ handleMultiSelectOptionChange }
                hideLabel
                width={ `${refContainerWidth - 72}px` }
                isClearable
                segmentKey={ segmentKey }
            />
        </div>
    );
};
