import styled from 'styled-components';
import {FileUploadPattern, IconNotificationSuccess} from '@snsw/react-component-library';
import {colors, fontSize} from '../CommonComponents/Typography/Typography.styled';

export const CustomFileUploadPattern = styled(FileUploadPattern)`
    > fieldset {
        margin-top: 4px
    }

    > div[class*="UploadedItemContainer"] {
        background-color: #ECF1F3;
    }

    > fieldset > p {
        display: none
    }

    [class^="DropZoneWrapper"] {
        margin-top: -0.5rem;
        background-color: ${colors.grey.iceBlue};
        flex-flow: row;
        min-height: 0;
        padding-bottom: 0.5rem;
        padding-top: 1rem;
        padding-left: 0;
        height: fit-content;
        text-align: left;
        border-radius: 5px;
        border-width: 2px;
    }

    [class^="UploadIcon"] {
        fill: ${colors.brand.snswSecondaryBlue};
        margin-top: 0;
        width: 2.75rem;
        height: 2.75rem;
        padding-right: 0.75rem;
        padding-left: 0;
    }

    [class^="DropZoneText"] {
        font-size: ${fontSize.body};
        display: flex;
        margin-left: 1.5rem;
    }

    [class^="DropZoneText"] p {
        color: ${colors.brand.snswDarkGrey};
    }

    [class^="FileInputButton"] {
        flex: 0 0 25%;
    }

    div[class^="UploadedItemContainer"] {
        font-size: ${fontSize.body};
        margin-top: -0.5rem;
        padding: 12px;
        border-radius: 2px;
    }
`;

export const CustomIconNotificationSuccess = styled(IconNotificationSuccess)`
    margin: 3rem 0 0 0;
`;