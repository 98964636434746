import React, {useContext, useState} from 'react';
import {CustomFileUploadPattern, CustomIconNotificationSuccess} from './FileUploadComponent.styled';
import {FormGroup} from '@snsw/react-component-library';
import CustomerCountComponent from '../CommonComponents/CustomerCountComponent/CustomerCountComponent';
import {CampaignContext} from '../SendCampaignComponent/SendCampaignComponent';
import {CustomerInPageAlert} from '../APIDocsPage/APIDocsContentComponent.styled';

export const TooManyCustomersErrorMessage = 'Too many customers per file. Try again with 50,000 customers or less.';

const FileUploadComponent = ({
    setRequest, showFileUpload, id, label, margin, errorMessage, setErrorMessage,
    legendText, maxMBSize, maxFiles, acceptedFormats
}) => {

    const {fileUploadCustomerCount, setFileUploadCustomerCount, duplicateCount, setDuplicateCount} = useContext(CampaignContext);
    const [uploadSuccess, setUploadSuccess] = useState(false);

    const uploadApi = {
        uploadFile: async (file) => {
            const resultStr = await new Promise((resolve) => {
                const fileReader = new FileReader();
                fileReader.onload = (e) => resolve(fileReader.result);
                fileReader.readAsText(file);
            });
            // trim empty or null lines
            const filteredLines = resultStr.replace(/\r\n/g, '\n').split('\n')
                .filter(line => line.trim() !== '');
            const noOfLines = filteredLines.length;
            if (noOfLines > 50000) {
                setErrorMessage(TooManyCustomersErrorMessage);
                return;
            } else {
                const uniqueLines = new Set(filteredLines);
                const noOfValidLines = uniqueLines.size;
                const noOfDuplicateLines = filteredLines.length - noOfValidLines;
                setDuplicateCount(noOfDuplicateLines);
                setRequest(prevState => ({
                    ...prevState,
                    file: file
                }));
                setUploadSuccess(true);
                setFileUploadCustomerCount(noOfValidLines);
                return file.name;
            }
        },
        removeFile: async (file) => {
            setRequest(prevState => ({
                ...prevState,
                file: null
            }));
            setUploadSuccess(false);
            return file.name;
        }
    };

    return (
        <>
            <div style={ {
                display: showFileUpload ? 'flex' : 'none',
                justifyContent: 'space-between', alignItems: 'center'
            } }>
                <div style={ {
                    display: showFileUpload ? 'block' : 'none',
                    gap: uploadSuccess ? '10px' : '0',
                    width: uploadSuccess ? '95%' : '100%'
                } }>
                    <FormGroup
                        id={ id }
                        label={ label }
                        margin={ margin }>
                        <CustomFileUploadPattern api={ uploadApi } name='file-upload' id='fileUpload'
                            aria-labelledby={ label }
                            data-testid='fileUpload' errorMessage={ errorMessage }
                            legendText={ legendText }
                            maxMBSize={ maxMBSize } maxFiles={ maxFiles }
                            acceptedFormats={ acceptedFormats }
                            onUploaded={ value => console.log('onUploaded has been fired with value', value[0]) }
                            onRemoved={ value => console.log('onRemoved has been fired with value', value[0]) }/>
                    </FormGroup>
                </div>
                {uploadSuccess && <CustomIconNotificationSuccess data-testid={ 'success-upload-icon' }/>}
            </div>

            {uploadSuccess &&
                <>
                    { duplicateCount > 0 && <div style={ { marginTop: '1rem', width: '95%' } }>
                        <CustomerInPageAlert compact variant='warning'>
                            <p style={ {marginLeft: '-0.75rem'} }>
                                {duplicateCount?.toLocaleString()} duplicate Customer ID{duplicateCount > 1 ? 's' : ''} detected and removed
                            </p>
                        </CustomerInPageAlert>
                    </div>}
                    <div style={ {display: showFileUpload ? 'block' : 'none'} }>
                        <CustomerCountComponent noOfCustomers={ fileUploadCustomerCount }/>
                    </div>
                </>}
        </>

    );
};

export default FileUploadComponent;