import Col from '@snsw/react-component-library/build/layout/Grid/Col.styled';
import {Textarea} from '@snsw/react-component-library';
import Heading from '@snsw/react-component-library/build/Components/Headings/Heading';
import Fieldset from '@snsw/react-component-library/build/Components/Form/Fieldset';
import Button from '@snsw/react-component-library/build/Components/Button/Button';
import Row from '@snsw/react-component-library/build/layout/Grid/Row.styled';
import React from 'react';
import {smsFormField, validateTextField} from '../CreateNotificationComponent/CreateNotificationComponent';
import {CharactersCounter} from '../CreateServiceComponent/CreateServiceComponent.styled';

const SmsContentFormComponent = ({
    inputErrors,
    setInputErrors,
    handleChange,
    smsContent,
    setShowSmsPreviewModal,
    inProd
}) => {
    const isInProd = (inProd != null) ? inProd : false;
    const openPreview = async () => {
        let previewIsValid = true;
        if (!validateTextField(smsContent, 'smsContent', setInputErrors)) {
            setInputErrors(prevState => ({
                ...prevState,
                smsContent: {
                    hasError: true,
                    errorMessage: 'Provide SMS content to preview or create notification'
                }
            }));
            previewIsValid = false;
        }
        if (previewIsValid) {
            setShowSmsPreviewModal(true);
        } else {
            const errorElement = document.querySelectorAll('span[class^=\'FormError\']')[0]?.previousSibling;
            errorElement?.scrollIntoView({behavior: 'smooth'});
        }
    };
    return (<>
        <Row style={ {paddingBottom:'4px'} }>
            <Col span={ 9 }>
                <Heading level={ 4 } style={ {height: 'fit-content', margin: '24px 0 0 0'} }>
                    {smsFormField.heading}
                </Heading>
                <Fieldset
                    legend= { smsFormField.content.legend }
                    smallLegend
                    margin={ {top: 16} }
                    errorMessage={ inputErrors.smsContent.errorMessage }
                    helpMessage={ smsFormField.content.helpMessage }
                    hasError={ inputErrors.smsContent.hasError }
                >
                    <Textarea
                        label={ smsFormField.content.label }
                        name={ 'smsContent' }
                        data-testid='smsTextArea'
                        hasError={ inputErrors.smsContent.hasError }
                        maxLength={ 1440 }
                        rows={ 'medium' }
                        onChange={ handleChange }
                        onBlur={ () => validateTextField(smsContent, 'smsContent', setInputErrors) }
                        disabled={ isInProd }
                        value={ smsContent }
                    />
                    <CharactersCounter>
                        {smsContent.length < 2 ? `${smsContent.length} character` : `${smsContent.length} characters`}
                    </CharactersCounter>

                </Fieldset>
                <div style={
                    {
                        display: 'flex',
                        flexWrap: 'nowrap',
                        justifyContent: 'start',
                        flexDirection: 'column',
                        rowGap: '32px',
                        marginTop: '32px'
                    }
                }>
                    <div style={
                        {
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            columnGap: '25px'
                        }
                    }>
                        <Button
                            data-testid='previewSmsDesignButton'
                            variant='secondary'
                            onClick={ openPreview }
                        >
                            Preview SMS
                        </Button>
                    </div>
                </div>
            </Col>
        </Row>
    </>);
};

export default SmsContentFormComponent;