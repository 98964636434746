import {notificationChannelFormField} from './CreateNotificationComponent';

export function switchPortfolio(channelsObj, serviceNotificationCategory) {

    let notificationTitle = '';
    if (serviceNotificationCategory === 'BUSINESS') {
        channelsObj[1].clarify = notificationChannelFormField.push.businessClarify;
        channelsObj[2].clarify = notificationChannelFormField.inbox.businessClarify;
        notificationTitle = 'business notification';
    } else {
        channelsObj[1].clarify = notificationChannelFormField.push.clarify;
        channelsObj[2].clarify = notificationChannelFormField.inbox.clarify;
        notificationTitle = 'notification';
    }

    return {channelsObj, notificationTitle};
}
